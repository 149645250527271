import * as React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { graphql, useStaticQuery } from "gatsby"

const NotFound = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  return (
    <Layout>
      <div className="px-4">
        <Head title={`404 | ${data.site.siteMetadata.author}`} />
        <h1 className="text-4xl text-slate-200">
          I forgot a redirect...{" "}
          <span role="img" aria-label="emoji">
            🙈
          </span>
        </h1>
        <p className="text-slate-500">
          You somehow ended here, either manually or by clicking some link I
          provided. Either way, there's no content here. Use the menu to head
          back to safety.
        </p>
      </div>
    </Layout>
  )
}

export default NotFound
